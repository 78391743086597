import BasePlugin from '../BasePlugin'

export default class OpenAddressProgramAdjustment extends BasePlugin {
  async execute (row, column, cell) {
    let element = cell.querySelector('.interactive')
    if (!element) {
      return false
    }

    let recordId = element.getAttribute('data-record-id')
    if (!recordId) {
      console.log('record id not set')
      return false
    }
    let typeId = element.getAttribute('data-type-id')
    if (!typeId) {
      console.log('type id not set')
      return false
    }
    this.context.$parent.openRegistryCard({
      registryId: 3390,
      cardId: 218,
      cardName: 'Корректировка Адресной программы',
      recordId: recordId,
      initialData: { attr_1_: typeId.toString() }
    })
  }
}
